html {
  overflow: hidden;
}

body {
  overflow: hidden;
}

.App {
  text-align: center;
  overflow: hidden;
}

.appHolder {
  width: 300px;
  height: 700px;
  display: inline-block;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.appHolderSwipe {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
}

form.form  {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
}

h1 {
  font-size: 18;
}
