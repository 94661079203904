div.swipeContainer {
  width: 100vw;
  height: 100vh;
  background: lightblue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.appHolderSwipe {
  
}